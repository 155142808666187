//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getAreaList } from '@/api/modular/system/areaManage'

export default {

  data () {
    return {
      queryParam: {},
      data: [],
      loading: true,
      // 定義展開過的節點的id數組
      expandedData: [],
      columns: [
        {
          title: '名稱',
          dataIndex: 'name'
        },
        {
          title: '層級',
          dataIndex: 'levelCode'
        },
        {
          title: '簡稱',
          dataIndex: 'shortName'
        },
        {
          title: '組合名',
          dataIndex: 'mergerName'
        },
        {
          title: '拼音',
          dataIndex: 'pinyin'
        },
        {
          title: '郵編',
          dataIndex: 'zipCode'
        },
        {
          title: '經度',
          dataIndex: 'lng'
        },
        {
          title: '緯度',
          dataIndex: 'lat'
        }
      ],
      selectedRowKeys: []
    }
  },

  created () {
    this.loadData()
  },

  methods: {
    loadData () {
      this.loading = true
      getAreaList(this.queryParam).then((res) => {
        if (res.success) {
          this.data = res.data
          this.removeEmptyChildren(this.data)
        }
      }).finally(() => {
        this.loading = false
      })
    },

    removeEmptyChildren(data) {
      if (data == null || data.length === 0) return
      for (let i = 0; i < data.length; i++) {
        const item = data[i]
        // 如果為最終子節點或其為“市轄區”，則其沒有子節點
        if (item.levelCode === 4 || (item.levelCode === 2 && item.name === '市轄區')) {
           item.children = null
        }
      }
    },
    onSelectChange (selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },
    onExpand(expanded, record) {
      if (expanded) {
        // 判斷其是否已經展開過
        const index = this.expandedData.indexOf(record.id)
        // 如果沒展開過，則請求接口
        if (index === -1) {
          this.queryParam.parentCode = record.areaCode
          getAreaList(this.queryParam).then((res) => {
            if (res.success) {
              // 設置為其子節點
              record.children = res.data
              this.removeEmptyChildren(record.children)
              // 將其放入展開過的id集合
              this.expandedData.push(record.id)
            }
          }).finally(() => {
            this.loading = false
          })
        }
      }
    }
  }
}

